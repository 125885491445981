import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Botpoison from '@botpoison/browser'
import { Translate, withLocalize } from 'react-localize-redux'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import FormField from '../FormField/FormField'
import SeparatorImage from '../SeparatorImage/SeparatorImage'

import { isLoading } from './ContactForm.module.scss'

const ContactForm = ({ translate, activeLanguage }) => {
  const [values, setValue] = useState({})
  const [errorVisible, setErrorVisibility] = useState(false)
  const [mailchimpMessage, setMailchimpMessage] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const botpoisonRef = useRef()

  const { code: lang } = activeLanguage

  useEffect(() => {
    botpoisonRef.current = new Botpoison({
      publicKey: 'pk_e707f397-59d7-4e65-a865-e0dffcd75896',
    })
  }, [])

  const handleChange = event => {
    const { name, value, checked, type } = event.target

    setValue(currValues => ({
      ...currValues,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const { email, subscribe } = values

    const subscribeToMailchimp = async () => {
      if (!subscribe) return

      const listFields = {}

      // Mailchimp language groups
      if (lang === 'es-es') {
        listFields['group[22913]'] = '1'
      } else if (lang === 'en-gb') {
        listFields['group[22913]'] = '2'
      }

      addToMailchimp(email, listFields).then(data => {
        const { msg } = data
        setMailchimpMessage(msg)
      })

      if (window.gtag) window.gtag('event', 'newsletter_subscribe')
    }

    setIsSubmitting(true)

    // Submit form
    try {
      const { solution } = await botpoisonRef.current.challenge()
      const response = await fetch('https://submit-form.com/EgVaOTq5', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...values,
          _botpoison: solution,
        }),
      })

      if (response.status !== 200) {
        console.log(response)
        setErrorVisibility(true)
      } else {
        setShowSuccess(true)
        subscribeToMailchimp()
      }

      setIsSubmitting(false)
    } catch (error) {
      console.log(error)
      setErrorVisibility(true)
      setIsSubmitting(false)
    }
  }

  if (!activeLanguage) return null

  return (
    <div
      className={`mx-auto max-w-md${
        isSubmitting ? ' pointer-events-none' : ''
      }`}
    >
      {errorVisible && (
        <div className="mb-4 text-terracotta text-lg">
          <Translate id="somethingWentWrong" />
        </div>
      )}
      {showSuccess ? (
        <div className="h-64 text-center">
          <SeparatorImage />
          <div className="text-xl tracking-tight">
            <Translate id="thankYou" />
          </div>
          <div>
            <Translate id="weGetInTouch" />
          </div>
          {mailchimpMessage && (
            <div
              className="mt-3 text-sm"
              dangerouslySetInnerHTML={{ __html: mailchimpMessage }}
            />
          )}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <FormField
              label={translate('yourName')}
              placeholder={translate('enterYourName')}
              name="name"
              required
              onChange={handleChange}
            />
          </div>
          <div className="mb-6">
            <FormField
              type="email"
              label={translate('yourEmailShort')}
              placeholder={translate('yourEmail')}
              name="email"
              required
              onChange={handleChange}
            />
          </div>
          <div className="mb-6">
            <FormField
              label={translate('yourMessage')}
              placeholder={translate('enterYourMessage')}
              name="message"
              element="textArea"
              required
              onChange={handleChange}
            />
          </div>
          <div className="mb-8">
            <FormField
              type="checkbox"
              label={translate('subscribeToNewsletter')}
              name="subscribe"
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className={`block w-full mt-2 py-3 font-500 text-white text-center uppercase tracking-wide text-sm bg-fern-green rounded-full focus:outline-none transition duration-500${
              isSubmitting ? ` ${isLoading}` : ''
            }`}
          >
            {isSubmitting ? (
              <Translate id="sending" />
            ) : (
              <Translate id="send" />
            )}
          </button>
        </form>
      )}
    </div>
  )
}

ContactForm.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object,
}

ContactForm.defaultProps = {
  translate: () => {},
  activeLanguage: {},
}

export default withLocalize(ContactForm)
