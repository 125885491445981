import React from 'react'
import PropTypes from 'prop-types'

import { content } from './ContactDescription.module.scss'

const ContactDescription = ({ html }) => {
  return <div className={content} dangerouslySetInnerHTML={{ __html: html }} />
}

ContactDescription.propTypes = {
  html: PropTypes.string,
}

ContactDescription.defaultProps = {
  html: '',
}

export default ContactDescription
