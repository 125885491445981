import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import onPageRender from '../hocs/onPageRender'
import Container from '../components/Container/Container'
import SectionTitle, {
  TITLE_TYPES,
} from '../components/SectionTitle/SectionTitle'
import ContactForm from '../components/ContactForm/ContactForm'
import Seo from '../components/Seo/Seo'
import ContactDescription from '../components/ContactDescription/ContactDescription'

const Contact = ({
  data: {
    page: { data },
  },
}) => {
  const {
    title: { text: title },
    description: { html: description },
  } = data
  return (
    <Container>
      <Seo title={title} />
      <div className="mx-auto max-w-lg pt-10 pb-12">
        <SectionTitle
          text={title}
          type={TITLE_TYPES.HANDWRITTEN_ALPHA}
          htmlElement="h1"
        />
        <ContactDescription html={description} />
      </div>
      <ContactForm />
    </Container>
  )
}

Contact.propTypes = {
  data: PropTypes.object,
}

export default onPageRender(Contact)

export const query = graphql`
  query($id: String!) {
    page: prismicContact(id: { eq: $id }) {
      data {
        title {
          text
        }
        description {
          html
        }
      }
    }
  }
`
