import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

export const SEPARATOR_VARIANTS = {
  A: 'a',
}

const imgProps = {
  alt: '',
  quality: 100,
  layout: 'constrained',
  placeholder: 'none',
}

const getVariant = variant => {
  switch (variant) {
    case SEPARATOR_VARIANTS.A:
      return (
        <StaticImage src="./assets/planta-a.png" width={160} {...imgProps} />
      )
    default:
      return null
  }
}

const SeparatorImage = ({ variant }) => {
  return (
    <div className="text-center select-none pointer-events-none">
      {getVariant(variant)}
    </div>
  )
}

SeparatorImage.propTypes = {
  variant: PropTypes.oneOf(Object.values(SEPARATOR_VARIANTS)),
}

SeparatorImage.defaultProps = {
  variant: SEPARATOR_VARIANTS.A,
}

export default SeparatorImage
