import React from 'react'
import PropTypes from 'prop-types'

import {
  labelClassname,
  customInputContainer,
  customInput,
} from './FormField.module.scss'

const FormField = ({
  label,
  type,
  name,
  placeholder,
  required,
  element,
  ...props
}) => {
  const textInputClassName =
    'block py-3 px-4 w-full border border-zinnwaldite focus:outline-none focus:border-fern-green rounded-xl'
  const checkBoxClassName = ''
  const elementProps = {
    name,
    placeholder,
    required,
    ...props,
  }

  const getClassName = type => {
    switch (type) {
      case 'checkbox':
        return checkBoxClassName
      default:
        return textInputClassName
    }
  }

  const getElement = element => {
    switch (element) {
      case 'textArea':
        return (
          <textarea className={textInputClassName} rows="4" {...elementProps} />
        )
      default:
        return (
          <div className={type === 'checkbox' ? customInputContainer : ''}>
            <input
              type={type}
              className={getClassName(type)}
              {...elementProps}
            />
            {type === 'checkbox' && <div className={customInput}></div>}
          </div>
        )
    }
  }

  return (
    <label
      className={`${
        type === 'checkbox' ? 'flex items-center' : 'block'
      } ${labelClassname}`}
    >
      <div className="order-1 mb-1 font-sans-regular text-sm">{label}</div>
      {getElement(element)}
    </label>
  )
}

FormField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  element: PropTypes.string,
  required: PropTypes.bool,
}

FormField.defaultProps = {
  label: '',
  type: 'text',
  name: '',
  placeholder: '',
  element: 'input',
  required: false,
}

export default FormField
